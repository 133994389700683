<template>
  <div class="text-center">
    <v-dialog
      v-model="openDialog"
      width="500"
      height="500"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>Crop Description Form </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="crop.cropvariety"
                label="Variety*"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="cropsel.length"
            small
            color="info"
            outlined
            rounded
            text
            @click="updateCrop"
          >
            <v-icon>mdi-content-save</v-icon> Update
          </v-btn>
          <v-spacer />

          <v-btn
            v-if="!cropsel.length"
            small
            outlined
            rounded
            text
            color="success"
            @click="saveNewCrop"
          >
            <v-icon>mdi-content-save</v-icon>
            Save as New Record
          </v-btn>
          <v-btn
            v-if="cropsel.length"
            small
            outlined
            rounded
            text
            color="error"
            @click="deleteCrop"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            text
            @click="closeDialog"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {

    props: ['openDialog', 'cropsel'],
    data: () => ({
      dialog: false,
      crop: {
        cropvariety: '',
        cropdesc_id: '',
      },
    }),

    watch: {
      cropsel: function (val) {
        if (val.length) {
          this.crop = val[0]
        } else {
          return val
        }
      },
    },

    mounted () {},

    methods: {
      closeDialog () {
        this.$emit('closeDlg', false)
      },
      saveNewCrop () {
        this.$store.dispatch('cropvariety/postData', this.crop)
        this.$toast.success('Save Complete')
        this.closeDialog()
      },
      async deleteCrop () {
        const res = await confirm('Delete Data')
        if (res) {
          this.$store.dispatch('cropvariety/deleteData', this.crop.id)
          this.$toast.success('Delete Complete')
        } else {
          this.$toast.info('Delete Cancelled')
        }
        this.closeDialog()
      },
      async updateCrop () {
        await this.$store.dispatch('cropvariety/updateData', this.crop)
        this.$toast.success('Update Complete')
        this.closeDialog()
      },
    },
  }
</script>
