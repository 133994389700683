<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="300"
      tile
    >
      <cropdescdialog
        :open-dialog="EditDialog"
        :cropsel="selected"
        @closeDlg="CloseDialog"
      />
      <v-card-title>
        Description

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
        />
      </v-card-title>
      <v-card-text>
        <v-fab-transition>
          <v-btn
            color="warning"
            fab
            dark
            small
            absolute
            bottom
            right
            @click="dialog()"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="cropdesc"
          :items-per-page="10"
          single-select
          show-select
          item-key="id"
          class="elevation-1"
          flat
          dense
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import {mapActions,mapGetters} from 'vuex';
  import cropdescdialog from './cropdescdialog.vue'

  export default {

    components: {
      cropdescdialog,
    },
    data: () => ({
      EditDialog: false,
      cropdesc: [],
      search: null,
      selected: [],
      headers: [
        {
          text: 'Crop Description',
          value: 'cropdesc',
        },
      ],
    }),

    watch: {
      '$store.state.cropdesc.data': function (val) {
        if (val.length > 0) {
          this.cropdesc = val
          this.$store.dispatch(
            'cropdesc/setCropClassid',
            (this.cropclass_id = val[0].cropclass_id),
          )
        }
      },
      selected: function (val) {
        if (val.length > 0) {
          this.$store.dispatch('cropdesc/setSelected', val[0].id)
          this.$store.dispatch('cropvariety/getData', val[0].id)
        }
      },
    },

    methods: {
      async getCropDesc () {
        // run get api function
        this.$store.dispatch('cropdesc/getData', this.cropclass_id)

        // set data from vuex storage
        this.cropdesc = await this['cropdesc/cropdescdata']
      },
      dialog () {
        this.EditDialog = true
      },
      CloseDialog (value) {
        this.EditDialog = value
        this.selected = []
        this.$store.dispatch('cropdesc/getData', this.cropclass_id)
      },
    },
  }
</script>
