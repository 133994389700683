<template>
  <div>
    <v-row>
      <v-col>
        <cropclass />
      </v-col>
      <v-col>
        <cropdesc />
      </v-col>
      <v-col>
        <cropvariety />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cropclass from "./cropclass.vue";
import cropdesc from "./cropdesc.vue";
import cropvariety from "./cropvariety.vue";

export default {
  components: {
    cropclass,
    cropdesc,
    cropvariety,
  },
}
</script>
