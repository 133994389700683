<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="300"
      tile
    >
      <cropdialog
        :open-dialog="EditDialog"
        :cropsel="selected"
        @closeDlg="CloseDialog"
      />
      <v-card-title>
        Classification

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
        />
      </v-card-title>
      <v-card-text>
        <v-fab-transition>
          <v-btn
            color="warning"
            fab
            dark
            small
            absolute
            bottom
            right
            @click="dialog()"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="cropclass"
          :items-per-page="10"
          single-select
          show-select
          item-key="id"
          class="elevation-1"
          flat
          dense
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import cropdialog from './cropdialog.vue'

  export default {

    components: {
      cropdialog,
    },
    data: () => ({
      EditDialog: false,
      cropclass: [],
      search: null,
      selected: [],
      headers: [
        {
          text: 'Crop Classification',
          value: 'cropclass',
        },
      ],
    }),

    computed: {
      ...mapGetters(['cropclass/cropclassdata', 'cropclass/cropclassbyId']),
      ...mapActions(['cropclass/getData']),
    },

    watch: {
      '$store.state.cropclass.data': function () {
        this.cropclass = this.$store.state.cropclass.data
      },
      selected: function (val) {
        if (val.length > 0) {
          this.$store.dispatch('cropdesc/getData', val[0].id)
        }
      },
    },

    mounted () {
      this.getCropClass()
    },
    methods: {
      async getCropClass () {
        // run get api function
        await this['cropclass/getData']

        // set data from vuex storage
        this.cropclass = await this['cropclass/cropclassdata']
      },
      dialog () {
        this.EditDialog = true
      },
      CloseDialog (value) {
        this.EditDialog = value
        this.selected = []
      },
    },
  }
</script>
