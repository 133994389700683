<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="300"
      tile
    >
      <cropvarietydialog
        :open-dialog="EditDialog"
        :cropsel="selected"
        @closeDlg="CloseDialog"
      />
      <v-card-title>
        Variety

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
        />
      </v-card-title>
      <v-card-text>
        <v-fab-transition>
          <v-btn
            color="warning"
            fab
            dark
            small
            absolute
            bottom
            right
            @click="dialog()"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="cropvariety"
          :items-per-page="10"
          single-select
          show-select
          item-key="id"
          class="elevation-1"
          flat
          dense
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import cropvarietydialog from './cropvarietydialog.vue'

  export default {

    components: {
      cropvarietydialog,
    },
    data: () => ({
      EditDialog: false,
      cropvariety: [],
      search: null,
      selected: [],
      headers: [
        {
          text: 'Crop Description',
          value: 'cropvariety',
        },
      ],
    }),

    watch: {
      '$store.state.cropvariety.data': async function (val) {
        this.cropvariety = val
      },
    },

    methods: {
      async getcropvariety () {
        // run get api function
        this.$store.dispatch('cropvariety/getData', this.cropclass_id)

        // set data from vuex storage
        this.cropvariety = await this['cropvariety/cropvarietydata']
      },
      dialog () {
        this.EditDialog = true
      },
      CloseDialog (value) {
        this.EditDialog = value
        this.selected = []
      },
    },
  }
</script>
